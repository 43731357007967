// @flow
/* globals jQuery */

import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PubNub from 'pubnub';
import { pick, pickBy } from 'lodash';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import PageContainer from './containers/PageContainer';
import CategoriesContainer from './containers/CategoriesContainer';
import { parseQuery } from '~/public/shared/utils/url';
import * as ItemsActions from './actions/items';
import { handleStoreChange } from './store/listeners';
import { FeatureFlagProvider } from '../dashboard/contexts/FeatureFlagContext';
import BoutiquesContainer from './containers/BoutiquesContainer';

export const history = createBrowserHistory();

const store = configureStore();
store.subscribe(handleStoreChange(store));

const onKeywordClear = () => {
  // Hack for clearing search box in header when keyword param is removed
  jQuery('input[type="search"][name="q"]').val('');
};

type Props = {
  pubnub: {
    subscribeKey: string,
    ssl: boolean,
    suppressLeaveEvents: boolean,
    heartbeatInterval: number,
    keepAlive: boolean,
  },
  followedItems: Array<number>,
  savedSearches: Array<Object>,
  filterKeys: Array<string>,
  featureFlags: Object,
  boutiques: Array<Object>,
};

class Application extends PureComponent<Props> {
  constructor(props: Props) {
    super();
    store.dispatch(ItemsActions.loadFollowedItems(window.EBTH.user.followed_item_ids));
    store.dispatch(ItemsActions.loadSavedSearches(props.savedSearches));
  }

  componentWillMount() {
    if (document && document.body) { document.body.setAttribute('data-no-turbolink', ''); }
  }

  componentWillUnmount() {
    if (document && document.body) { document.body.removeAttribute('data-no-turbolink'); }
  }

  render() {
    const pubnub = new PubNub(this.props.pubnub);
    const { featureFlags } = this.props;
    return (
      <Provider store={store}>
        <BrowserRouter history={history}>
          <FeatureFlagProvider value={featureFlags}>
            <Switch>
              <Route
                exact
                path="/sales/:sale_slug"
                render={(props) => {
                  props.match.queryParams = pick(parseQuery(props.location), this.props.filterKeys);

                  const saleSlug = props.match.params.sale_slug || '';
                  props.match.path_slug = props.location.pathname;
                  const mergedParams = {
                    sale_id: saleSlug.split('-')[0] || undefined,
                    ...props.match.queryParams,
                  };
                  props.match.parsedParams = pickBy(pick(mergedParams, this.props.filterKeys));

                  return (
                    <PageContainer
                      {...props}
                      pubnub={pubnub}
                      onKeywordClear={onKeywordClear}
                      featureFlags={featureFlags}
                    />
                  );
                }}
              />
              <Route
                exact path="/categories" render={(props) => {
                  return (
                    <CategoriesContainer {...props} />
                  );
                }}
              />
              <Route
                exact path="/boutiques" render={(props) => {
                  props.boutiques = this.props.boutiques;
                  return (
                    <BoutiquesContainer {...props} />
                  );
                }}
              />
              <Route
                render={(props) => {
                  return (
                    <PageContainer
                      {...props}
                      pubnub={pubnub}
                      onKeywordClear={onKeywordClear}
                      featureFlags={featureFlags}
                    />
                  );
                }}
              />
            </Switch>
          </FeatureFlagProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default Application;
