import { stringifyQuery } from '~/public/shared/utils/url';

export default (params) => {
  const defaults = {
    sort: 'recommended',
    page: 1,
    per_page: 24,
  };
  if (window.location.href.includes('/boutiques')) {
    defaults.boutiques = true;
  }

  const query = stringifyQuery(Object.assign({}, defaults, params));

  return fetch(`/api/v1/twosearch/items?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XM20.LHttpRequest',
      Authorization: 'Token token=7a2ab11cfe70777231c82cf4325c6e96',
    },
  }).then((response) => {
    return response.json().then((json) => {
      if (response.ok) {
        return json;
      } else {
        throw Error(response.statusText);
      }
    });
  });
};
